<template>
  <div class="dialog-area">
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="_isShow"
      width="1000px"
      top="0"
      :close-on-click-modal="false"
      @open="onPermissionChanged()"
      :destroy-on-close="true"
      @close="closePermissionSettingDialog()"
    >
      <template #title>
        <div class="flex items-center">
          <div class="bold">
            {{ $t('location.setting') + ` |  ${projectName} Ver ${projectVer}` }}
          </div>
          <el-button v-if="isEdit" type="danger" class="ml-9" @click="onDeletePermission">
            {{ $t('delete') }}
          </el-button>
        </div>
      </template>
      <el-form :disabled="!isFormEditable">
        <div>
          <el-select v-model="form.availableBarcodeTypes" @change="onAvailBCChanged" multiple>
            <el-option v-for="(bcType, key) in bcTypes" :key="key" :value="key" :label="bcType.name" />
          </el-select>

          <div class="overflow-x-auto my-6">
            <table class="w-full rounded text-center">
              <thead class="bg-gray-200 work-break">
                <tr class="bg-gray-custom">
                  <th class="border-solid border border-gray-300 px-5 py-4 text-center align-middle w-5 font-bold">
                    {{
                      `${$t('operation')
                        .toString()
                        .charAt(0)
                        .toUpperCase()}${$t('operation')
                        .toString()
                        .slice(1)
                        .toLowerCase()}`
                    }}
                  </th>
                  <th
                    v-for="(permission, idx) in form.trackingPermissions"
                    :key="idx"
                    class="border-solid border border-gray-300 px-5 py-4 text-center align-middle font-bold"
                  >
                    {{ trackpoints[idx].name }}
                  </th>
                </tr>
              </thead>
              <tbody class="overflow-auto bg-white">
                <tr>
                  <td class="px-2 py-3 border-solid border border-gray-300 whitespace-nowrap align-middle font-bold">
                    {{ $t('location.stock_in') }}
                  </td>
                  <td
                    v-for="(permission, idx) in form.trackingPermissions"
                    :key="idx"
                    class="px-2 py-3 border-solid border border-gray-300 whitespace-nowrap align-middle"
                  >
                    <el-checkbox v-model="permission.canInStock"></el-checkbox>
                  </td>
                </tr>

                <tr>
                  <td class="px-2 py-3 border-solid border border-gray-300 whitespace-nowrap align-middle font-bold">
                    {{ $t('location.stock_out') }}
                  </td>
                  <td
                    v-for="(permission, idx) in form.trackingPermissions"
                    :key="idx"
                    class="px-2 py-3 border-solid border border-gray-300 whitespace-nowrap align-middle"
                  >
                    <el-checkbox v-model="permission.canOutStock"></el-checkbox>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mt-4 pb-4 rounded-md bg-white border-solid border border-gray-300">
          <div class="mt-6 ml-6 mb-3 font-bold">{{ $t('location.target_item') }}</div>
          <div
            v-for="(item, idx) in form.locationItems"
            :key="idx"
            class="flex items-center justify-center relative mb-4"
          >
            <div
              class="rounded-md w-2/3 mt-2 mb-3 mx-8 px-2 pt-2 pb-4 border-solid border border-gray-300 whitespace-nowrap align-middle"
            >
              <div class="my-3 flex items-center">
                <div class="w-16 mr-2 flex justify-end font-bold">{{ $t('Name') }}</div>
                <el-input v-model="item.label" class="w-70 px-2" />
              </div>
              <div class="my-3 flex items-center">
                <div class="w-16 mr-2 flex justify-end font-bold">{{ $t('location.target') }}</div>
                <el-select v-model="item.barcodeTypeCode" class="w-40 px-2">
                  <el-option
                    v-for="bcTypeKey in form.availableBarcodeTypes"
                    :key="bcTypeKey"
                    :value="bcTypeKey"
                    :label="bcTypes[bcTypeKey].name"
                  />
                </el-select>
                <el-select v-model="item.function" @change="onItemFunctionChanged($event, idx)" class="w-24 px-2">
                  <el-option
                    v-for="(label, key) in getTargetActionOptions(item.barcodeTypeCode)"
                    :key="key"
                    :value="key"
                    :label="label"
                  />
                </el-select>
                <el-select
                  v-if="isSumFunction(item.function)"
                  v-model="item.field"
                  @change="onItemFieldChanged($event, item.barcodeTypeCode, idx)"
                  class="px-2"
                >
                  <el-option
                    v-for="(field, key) in getCustomFields(item.barcodeTypeCode)"
                    :key="key"
                    :value="key"
                    :label="field.label"
                  />
                </el-select>
              </div>
              <div class="my-3 flex items-center">
                <div class="w-16 mr-2 flex justify-end font-bold">{{ $t('location.unit') }}</div>
                <el-input class="w-40 px-2" v-model="item.unit" />
              </div>
            </div>
            <div class="absolute bottom-0">
              <el-button @click="onRemoveItem(idx)" type="info" icon="el-icon-minus" size="small" circle class="mx-2" />
              <el-button
                v-if="idx === form.locationItems.length - 1"
                @click="onAddItem()"
                type="primary"
                icon="el-icon-plus"
                size="small"
                circle
                class="mx-2"
              />
            </div>
          </div>
          <div v-if="form.locationItems.length === 0" class="flex items-center justify-center">
            <el-button @click="onAddItem()" type="primary" icon="el-icon-plus" size="small" circle class="mx-2" />
          </div>
        </div>
      </el-form>

      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="closePermissionSettingDialog()">
              {{ $t('barcode_type.cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="save">
              {{ $t(isEdit ? 'save' : 'add') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { removeIdxList } from '@/utils/arrayUtils'
import {
  IActivationCustomField,
  IBarcodeDefinitionType,
  ILocationProjectPermission,
  ILocationSettingItem,
  IProject,
  IProjectDetail,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import { ECustomFieldType, ELocationItemFunction } from 'smartbarcode-web-core/src/utils/enums/index'
@Options({
  components: {},
  directives: {},
  emits: ['update:isShow', 'onAddPermission', 'onUpdatePermission', 'onDeletePermission'],
  name: 'LocationPermissionDialogDialog',
})
export default class LocationPermissionDialog extends Vue {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean
  @Prop({ type: Number }) readonly recordIndex!: number
  @Prop({ type: Object }) readonly locationPermission!: ILocationProjectPermission
  @Prop({ type: Object }) readonly project!: IProject | IProjectDetail
  @Prop({ type: Boolean }) readonly isFormEditable!: boolean

  form: ILocationProjectPermission = {} as ILocationProjectPermission

  curBCType = [] as string[]

  get projectName() {
    return (this.project as IProject).name ?? (this.project as IProjectDetail).mainInfo?.name ?? ''
  }

  get projectVer() {
    return (this.project as IProject).version ?? (this.project as IProjectDetail).mainInfo?.version ?? ''
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.project.trackPoints ?? {}
  }

  get bcTypes(): Record<string, IBarcodeDefinitionType> {
    return this.project.barcodeTypes ?? {}
  }

  isSumFunction(funcName: string) {
    return ELocationItemFunction.SUM === funcName
  }

  onAvailBCChanged(bcTypeKeys: string[]) {
    removeIdxList(
      this.form.locationItems,
      this.form.locationItems.filter((i) => !bcTypeKeys.includes(i.barcodeTypeCode)).map((i, idx) => idx)
    )
  }

  getTargetActionOptions(bcTypeCode: string) {
    if (isEmpty(bcTypeCode)) return []
    return {
      [ELocationItemFunction.COUNT]: this.$t('location.count'),
      [ELocationItemFunction.SUM]: this.$t('location.sum'),
    }
  }

  getCustomFields(bcTypeKey: string): Record<string, IActivationCustomField> {
    return Object.entries(this.bcTypes[bcTypeKey].activationFields.customFields ?? {})
      .filter(([key, value]) => value.fieldType === ECustomFieldType.NUMBER)
      .reduce(
        (acc, [fieldKey, fieldValue]) => ({
          ...acc,
          [`activationData.customFields.${fieldKey}`]: fieldValue,
        }),
        {}
      )
  }

  closePermissionSettingDialog() {
    this.resetForm()
    this._isShow = false
  }

  onRemoveItem(idx: number) {
    this.form.locationItems.splice(idx, 1)
  }

  onItemFunctionChanged(func: string, itemIdx: number) {
    if (func === ELocationItemFunction.COUNT) {
      this.form.locationItems[itemIdx].customFieldType = 'number'
    }
  }

  onItemFieldChanged(fieldKey: string, bcTypeKey: string, itemIdx: number) {
    const fieldType = this.getCustomFields(bcTypeKey)[fieldKey].fieldType
    this.form.locationItems[itemIdx].customFieldType = fieldType
  }

  onAddItem() {
    this.form.locationItems.push({ ...this.defaultLocationItem } as ILocationSettingItem)
  }

  isEdit = false

  get defaultLocationItem(): ILocationSettingItem {
    return {
      barcodeTypeCode: this.form?.availableBarcodeTypes?.[0] ?? '',
      function: ELocationItemFunction.COUNT,
      customFieldType: 'number',
      field: '',
      label: '',
      unit: '',
    }
  }

  get defaultPermission(): ILocationProjectPermission {
    return {
      availableBarcodeTypes: [],
      locationItems: [],
      trackingPermissions: Object.keys(this.trackpoints ?? {}).reduce(
        (acc, idx: string) => ({
          ...acc,
          [idx]: { CanInStock: false, canOutStock: false },
        }),
        {}
      ),
    }
  }

  onDeletePermission() {
    this.$emit('onDeletePermission', this.recordIndex)
    this._isShow = false
  }

  save() {
    // TODO: ADD VALIDATION IF NECESSARY
    const eventType = this.isEdit ? 'onUpdatePermission' : 'onAddPermission'
    this.$emit(eventType, cloneDeep(this.form))

    this.closePermissionSettingDialog()
  }

  resetForm() {
    this.form = cloneDeep(this.defaultPermission)
  }

  @Watch('locationPermission', { deep: true })
  onPermissionChanged() {
    if (!this._isShow) return

    this.isEdit = !isEmpty(this.locationPermission)
    this.form = cloneDeep({
      ...this.defaultPermission, // default permission data
      ...(this.isEdit && this.locationPermission), // init permission data
      // ...(isEmpty(this.locationPermission.locationItems) && { locationItems: [this.defaultLocationItem] }), // init default location item
    })
  }
}
</script>
<style lang="scss" scoped>
.dialog-area :deep() {
  .el-overlay .el-dialog {
    max-width: 1000px;
  }

  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin-top: 3px;
  }
  .el-form-item {
    margin-bottom: 16px;
    &__item {
      top: calc(100% - 5px);
    }
  }
}
</style>
