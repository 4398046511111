export function validateIndex(idx: number) {
  return idx > -1
}

export function removeIdxList(arr: unknown[], idxList: number[]) {
  for (let i = idxList.length - 1; i >= 0; i--) arr.splice(idxList[i], 1)
}

export function removeIf<T>(arr?: unknown[], predicate?: (item: T, idx: number) => boolean | undefined | null) {
  if (!arr) return
  for (let i = arr.length - 1; i >= 0; i--) {
    if (predicate && predicate(arr[i] as T, i)) arr.splice(i, 1)
  }
}
