
import { removeIdxList } from '@/utils/arrayUtils'
import {
  IActivationCustomField,
  IBarcodeDefinitionType,
  ILocationProjectPermission,
  ILocationSettingItem,
  IProject,
  IProjectDetail,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import { ECustomFieldType, ELocationItemFunction } from 'smartbarcode-web-core/src/utils/enums/index'
@Options({
  components: {},
  directives: {},
  emits: ['update:isShow', 'onAddPermission', 'onUpdatePermission', 'onDeletePermission'],
  name: 'LocationPermissionDialogDialog',
})
export default class LocationPermissionDialog extends Vue {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean
  @Prop({ type: Number }) readonly recordIndex!: number
  @Prop({ type: Object }) readonly locationPermission!: ILocationProjectPermission
  @Prop({ type: Object }) readonly project!: IProject | IProjectDetail
  @Prop({ type: Boolean }) readonly isFormEditable!: boolean

  form: ILocationProjectPermission = {} as ILocationProjectPermission

  curBCType = [] as string[]

  get projectName() {
    return (this.project as IProject).name ?? (this.project as IProjectDetail).mainInfo?.name ?? ''
  }

  get projectVer() {
    return (this.project as IProject).version ?? (this.project as IProjectDetail).mainInfo?.version ?? ''
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.project.trackPoints ?? {}
  }

  get bcTypes(): Record<string, IBarcodeDefinitionType> {
    return this.project.barcodeTypes ?? {}
  }

  isSumFunction(funcName: string) {
    return ELocationItemFunction.SUM === funcName
  }

  onAvailBCChanged(bcTypeKeys: string[]) {
    removeIdxList(
      this.form.locationItems,
      this.form.locationItems.filter((i) => !bcTypeKeys.includes(i.barcodeTypeCode)).map((i, idx) => idx)
    )
  }

  getTargetActionOptions(bcTypeCode: string) {
    if (isEmpty(bcTypeCode)) return []
    return {
      [ELocationItemFunction.COUNT]: this.$t('location.count'),
      [ELocationItemFunction.SUM]: this.$t('location.sum'),
    }
  }

  getCustomFields(bcTypeKey: string): Record<string, IActivationCustomField> {
    return Object.entries(this.bcTypes[bcTypeKey].activationFields.customFields ?? {})
      .filter(([key, value]) => value.fieldType === ECustomFieldType.NUMBER)
      .reduce(
        (acc, [fieldKey, fieldValue]) => ({
          ...acc,
          [`activationData.customFields.${fieldKey}`]: fieldValue,
        }),
        {}
      )
  }

  closePermissionSettingDialog() {
    this.resetForm()
    this._isShow = false
  }

  onRemoveItem(idx: number) {
    this.form.locationItems.splice(idx, 1)
  }

  onItemFunctionChanged(func: string, itemIdx: number) {
    if (func === ELocationItemFunction.COUNT) {
      this.form.locationItems[itemIdx].customFieldType = 'number'
    }
  }

  onItemFieldChanged(fieldKey: string, bcTypeKey: string, itemIdx: number) {
    const fieldType = this.getCustomFields(bcTypeKey)[fieldKey].fieldType
    this.form.locationItems[itemIdx].customFieldType = fieldType
  }

  onAddItem() {
    this.form.locationItems.push({ ...this.defaultLocationItem } as ILocationSettingItem)
  }

  isEdit = false

  get defaultLocationItem(): ILocationSettingItem {
    return {
      barcodeTypeCode: this.form?.availableBarcodeTypes?.[0] ?? '',
      function: ELocationItemFunction.COUNT,
      customFieldType: 'number',
      field: '',
      label: '',
      unit: '',
    }
  }

  get defaultPermission(): ILocationProjectPermission {
    return {
      availableBarcodeTypes: [],
      locationItems: [],
      trackingPermissions: Object.keys(this.trackpoints ?? {}).reduce(
        (acc, idx: string) => ({
          ...acc,
          [idx]: { CanInStock: false, canOutStock: false },
        }),
        {}
      ),
    }
  }

  onDeletePermission() {
    this.$emit('onDeletePermission', this.recordIndex)
    this._isShow = false
  }

  save() {
    // TODO: ADD VALIDATION IF NECESSARY
    const eventType = this.isEdit ? 'onUpdatePermission' : 'onAddPermission'
    this.$emit(eventType, cloneDeep(this.form))

    this.closePermissionSettingDialog()
  }

  resetForm() {
    this.form = cloneDeep(this.defaultPermission)
  }

  @Watch('locationPermission', { deep: true })
  onPermissionChanged() {
    if (!this._isShow) return

    this.isEdit = !isEmpty(this.locationPermission)
    this.form = cloneDeep({
      ...this.defaultPermission, // default permission data
      ...(this.isEdit && this.locationPermission), // init permission data
      // ...(isEmpty(this.locationPermission.locationItems) && { locationItems: [this.defaultLocationItem] }), // init default location item
    })
  }
}
