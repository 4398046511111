
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import LocationPermissionDialog from '@/components/common/LocationPermissionDialog.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import IconExport from '@/components/svg/IconExport.vue'
import IconPrinter from '@/components/svg/IconPrinter.vue'
import {
  CLEAR_DETAIL_LOCATION,
  FETCH_LOCATION,
  LOAD_LOCATION_LIST,
  LOAD_LOCATION_PERMISSIONS,
  LOAD_PROJECTS_LIST,
  SET_HAVE_NEW_EXPORT_RECORD,
  SET_PAGE_NOT_FOUND,
} from '@/store/actions'
import { createLocation, exportLocation, fetchProject, updateLocation } from '@/utils/api'
import { DEFAULT_LOCATION } from '@/utils/constants'
import errorHandler from '@/utils/errorHandler'
import { openMessage } from '@/utils/utils'
import JsBarcode from 'jsbarcode'
import { jsPDF as JsPDF } from 'jspdf'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import { maska } from 'maska'
import QRCode from 'qrcode'
import { EExportType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  ICommonSearch,
  ILocation,
  ILocationPayload,
  ILocationProjectPermission,
  ILocationValueItem,
  IPaginationPayload,
  IProject,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
export interface ILocationPermission extends ILocationProjectPermission {
  projectId: string
  projectCode: string
  projectName: string
  projectVersion: number
  totalBarcodes: number
  items: ILocationValueItem[]
}

export interface ISimpleProject {
  projectId: string
  projectVersion: string
  projectCode: string
}

@Options({
  components: { PageHeader, LocationPermissionDialog, IconPrinter, IconExport, ItemNotFound },
  directives: { maska },
  name: 'LocationDetail',
})
export default class LocationDetail extends mixins(ValidateForm, DataTableMixin) {
  dataSearch = {} as ICommonSearch
  isSaveDisabled = false

  isShowPermissionSettingDialog = false
  locationForm = {} as ILocation

  loading = false
  rules = []

  currentPrjCode = ''
  currentPrjVer = 1

  dialogFormModelData = {}

  get maxProjectVersion(): number {
    return this.currentProject?.version ?? 1
  }

  get currentProject(): IProject | undefined {
    return this.getProjectsList.find((i) => i.code === this.currentPrjCode)
  }

  isDisableVersion(ver: number) {
    if (!this.currentPrjCode) return false
    return !!(this.existProjectPermissions[this.currentPrjCode] ?? []).find((i) => Number(i.projectVersion) === ver)
  }

  existProjectPermissions: Record<string, ISimpleProject[]> = {}

  print() {
    const img = document.querySelector('#qr-code')
    const width = 55
    const height = 40
    const barcodeWidth = 38
    const paddingTop = 1
    const doc = new JsPDF({ orientation: 'landscape', unit: 'mm', format: [height, width] })
    doc.setFontSize(10)
    doc.addImage(img as HTMLCanvasElement, 'JPEG', (width - barcodeWidth) / 2, paddingTop, barcodeWidth, barcodeWidth)

    doc.save(`sticker-${this.id}.pdf`)
  }

  printBarcode() {
    const myCanvas = document.getElementById('barcode') as HTMLCanvasElement
    const barcodeWidth = myCanvas.width
    const barcodeHeight = myCanvas.height
    const doc = new JsPDF({ orientation: 'landscape', unit: 'mm', format: [barcodeWidth, barcodeHeight] })
    doc.setFontSize(10)
    doc.addImage(myCanvas, 'JPEG', 0, 0, barcodeWidth, barcodeHeight)

    doc.save(`sticker-${this.id}.pdf`)
  }

  permissions = [] as ILocationPermission[]
  // @Watch('currentPrjCode')
  async fetchPermissions() {
    // new location don't have any permission for loading
    let totalItems = this.permissions.length
    if (this.isEdit) {
      this.loading = true
      const paginationParams: IPaginationPayload = {
        skip: (this.currentPageNum - 1) * this.itemsPerPage,
        count: this.itemsPerPage,
      }

      const { permissions, total, projects } = await this.$store
        .dispatch(LOAD_LOCATION_PERMISSIONS, {
          locationId: this.id,
          projectCode: this.currentPrjCode,
          ...paginationParams,
        })
        .finally(() => (this.loading = false))

      this.existProjectPermissions = groupBy(projects as ISimpleProject[], (i) => i.projectCode)
      this.permissions = permissions
      totalItems = total
    }

    this.displayItems = this.permissions
    this.sortDisplayItems()
    this.totalPaging = totalItems
  }

  get displayRecords() {
    // add index for permission row
    return (this.displayItems as ILocationPermission[]).map((i, idx) => ({
      index: idx,
      ...i,
    }))
  }

  get isPermssionDialogFormEditable() {
    // Todo Reuse this log
    // return isEmpty(this.selectedPermission)
    return true
  }

  ALL_VERSION = 0
  onProjectChagned() {
    this.currentPrjVer = 0
    this.currentPageNum = 1
  }

  trackpoints: Record<string, ITrackpoint> = {}
  bcTypes: Record<string, IBarcodeDefinitionType> = {}

  selectedProject?: IProject = {} as IProject
  @Watch('currentPrjCode')
  @Watch('currentPrjVer')
  async onPrjChanged() {
    if (isEmpty(this.currentPrjCode) || this.currentPrjVer === this.ALL_VERSION) return
    this.setSelectedProject(this.currentPrjCode, this.currentPrjVer)
  }

  selectedPermission = {} as ILocationPermission
  selectedPermissionIndex = -1
  onAddPermission() {
    this.selectedPermission = {} as ILocationPermission
    this.selectedPermissionIndex = -1
    this.isShowPermissionSettingDialog = true
  }

  async setSelectedProject(code: string, ver: number) {
    this.loading = true
    this.selectedProject = (await fetchProject(code, `${ver}`)) as IProject
    this.loading = false
  }

  setSelectedPermission(permission: { index: number } & ILocationPermission) {
    this.selectedPermission = permission
    this.selectedPermissionIndex = permission.index
  }

  async onEditPermission(permission: { index: number } & ILocationPermission) {
    // load settings and data to form
    await this.setSelectedProject(permission.projectCode, permission.projectVersion)
    this.setSelectedPermission(permission)

    this.isShowPermissionSettingDialog = true
  }

  get defaultPermission(): ILocationPermission {
    return {
      projectId: this.selectedProject?.id ?? '',
      projectCode: this.selectedProject?.code ?? '',
      projectName: this.selectedProject?.name ?? '',
      projectVersion: this.selectedProject?.version ?? 1,
      totalBarcodes: 0,
      availableBarcodeTypes: [],
      locationItems: [],
      trackingPermissions: Object.keys(this.trackpoints ?? {}).reduce(
        (acc, idx: string) => ({
          ...acc,
          [idx]: { CanInStock: false, canOutStock: false },
        }),
        {}
      ),
      items: [],
    } as ILocationPermission
  }

  addPermission(permission: ILocationPermission) {
    this.existProjectPermissions[this.currentPrjCode] = [
      ...(this.existProjectPermissions[this.currentPrjCode] ?? []),
      {
        projectId: this.selectedProject?.id ?? '',
        projectCode: this.selectedProject?.code ?? '',
        projectVersion: String(this.selectedProject?.version ?? 1),
      },
    ]
    this.currentPrjVer = this.ALL_VERSION

    this.permissions.push({
      ...this.defaultPermission,
      ...permission,
      items: permission.locationItems.map((i) => ({ unit: i.unit, label: i.label, value: 0 })), // for display only
    })
  }

  updatePermission(permission: ILocationPermission) {
    this.selectedPermission = {} as ILocationPermission // reset pointer
    const idx = this.permissions.findIndex(
      (p) => p.projectCode === permission.projectCode && p.projectVersion === permission.projectVersion
    )

    // update permission list
    this.permissions[idx] = {
      ...this.defaultPermission,
      ...permission,
      items: permission.locationItems.map((i) => ({ unit: i.unit, label: i.label, value: 0 })), // for display only
    }
  }

  deletePermission(idx: number) {
    // remove record in existProjectPermissions
    const prjCode = this.permissions[idx].projectCode
    const existIdx = this.existProjectPermissions[prjCode].findIndex(
      (i) => i.projectVersion === String(this.permissions[idx].projectVersion)
    )
    this.existProjectPermissions[prjCode].splice(existIdx, 1)

    // remove in permissions
    this.permissions.splice(idx, 1)
  }

  onSaveLocation(formName: string) {
    this.isSaveDisabled = true
    this.$refs[formName].validate((valid: string) => {
      if (valid) this.saveLocation()
      else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  get id() {
    return this.$route.params.id
  }

  backToLocationList() {
    this.$router.push({ name: 'location' })
  }

  async saveLocation() {
    try {
      this.loading = true
      const id = this.id
      const location = {
        ...this.locationForm,
        locationId: id,
        locationProjectPermissions: this.permissions.reduce(
          (acc, i) => ({
            ...acc,
            [i.projectId]: {
              availableBarcodeTypes: i.availableBarcodeTypes,
              trackingPermissions: i.trackingPermissions,
              locationItems: i.locationItems,
            },
          }),
          {}
        ),
      } as ILocationPayload

      // perform validation location form
      if (isEmpty(location.name)) {
        openMessage(this.$t('location.name_must_not_empty'), 'error')
        return
      }

      try {
        this.loading = true
        await (this.isEdit ? updateLocation(location) : createLocation(location))

        await this.$store.dispatch(LOAD_LOCATION_LIST)
        openMessage(this.$t('save_successful'), 'success')
        this.backToLocationList()
      } catch (e) {
        errorHandler(e)
      } finally {
        this.loading = false
      }
    } catch (err) {
      errorHandler(err)
    } finally {
      this.isSaveDisabled = false
      this.loading = false
    }
  }

  async onExportCSV() {
    await exportLocation(this.id)
      .then(() => this.$store.commit(SET_HAVE_NEW_EXPORT_RECORD, { exportType: EExportType.BARCODE, isHaveNew: true }))
      .catch((e) => errorHandler(e))
  }

  goToLocationList() {
    this.$router.push({ name: 'location' })
  }

  async loadForm() {
    this.locationForm = { ...DEFAULT_LOCATION }
    if (this.id) {
      this.loading = true
      await this.$store
        .dispatch(FETCH_LOCATION, this.id)
        .then((location: ILocation) => (this.locationForm = { ...location }))
        .catch(() => this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'location.location' }))
        .finally(() => (this.loading = false))
    } else this.$store.commit(CLEAR_DETAIL_LOCATION)
  }

  @Watch('$store.state.location.location')
  getInfo() {
    this.locationForm = { ...DEFAULT_LOCATION, ...cloneDeep(this.$store.state.location.location) }
  }

  async initList() {
    await this.loadForm()

    await this.fetchPermissions()
  }

  get getProjectsList(): IProject[] {
    return this.$store.state.project?.originalProjects.filter(
      (item: IProject) => !(item.isDraft && item.isDraft === true)
    )
  }

  qrcodeData: string | undefined = ''

  isEdit = false
  async created() {
    this.isEdit = !!this.id
    if (this.isEdit) {
      this.qrcodeData = await QRCode.toDataURL(this.id)
      JsBarcode('#barcode', this.id, {
        format: 'CODE39',
        displayValue: false,
        margin: 30,
      })
    }

    if (!this.$store.state.project?.isLoaded) {
      this.loading = true
      // project list for select-box
      await this.$store.dispatch(LOAD_PROJECTS_LIST)
      this.loading = false
    }

    const currentPrj = this.getProjectsList?.[0]
    this.currentPrjCode = currentPrj.code ?? ''
    this.currentPrjVer = this.ALL_VERSION

    await this.initList()
  }
}
