<template>
  <SvgBase iconName="IconExport" width="16" height="15" fill="none">
    <path d="M9 1L1 1L1 14L9 14" :stroke="color" stroke-width="1.5" />
    <path
      d="M11.9375 2.5L10.7919 3.769L12.8881 6.1L6 6.1L6 7.9L12.8881 7.9L10.7919 10.231L11.9375 11.5L16 7L11.9375 2.5Z"
      :fill="color"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconExport extends Vue {}
</script>
