<template>
  <SvgBase iconName="printer" width="34" height="34">
    <path d="M9.03787 28.3116H2V8.48682H32V28.3116H24.9621" :stroke="color" stroke-width="3.5" />
    <path d="M6.35828 9.84342V2H27.6415V9.84342" :stroke="color" stroke-width="3.5" />
    <path d="M25.1995 17.2373H8.80042V33.6364H25.1995V17.2373Z" :stroke="color" stroke-width="3.5" />
    <path d="M13.3623 22.8086H20.6376" :stroke="color" stroke-width="3.5" />
    <path d="M13.3623 28.0742H20.6376" :stroke="color" stroke-width="3.5" />
  </SvgBase>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPrinter extends Vue {}
</script>
